$options: stretch,center,flex-start,flex-end,baseline,initial,inherit;

.align-self-auto {
    align-self: auto;
}
@each $option in $options {
  .align-items-#{$option} {
    align-items: $option;
  }
  .align-self-#{$option} {
    align-self: $option;
  }
}
