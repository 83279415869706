.header {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1009; /* must be more than #osunavbar */
}

.header-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.navbar-menu {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 0 !important;
    float: none;
    text-align: left;
}

.navbar-menu a {
    color: #333333;
    font-weight: 600;
    transition: ease-in-out color .15s;
}

.navbar-menu a:hover {
    text-decoration: underline;
}

.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.navbar-nav-active {
    color: rgba(0, 0, 0, 0.9) !important;
}

.navbar-nav-c4u {
    color: rgb(187, 0, 0) !important;
}