.ellipsis {
    text-overflow: ellipsis;
}
.capitalize {
    text-transform: capitalize;
}
.lowercase {
    text-transform: lowercase;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}