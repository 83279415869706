.order-status-select {
    width: 7rem;
}

.order-table-expand-row {
    padding-left: 1rem;
    padding-right: 1rem;
}

.order-fulfillment-btn {
    width: 8rem;
}