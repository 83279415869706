@import "./imports";

$osu-red: rgb(187, 0, 0);

.osu-red {
  color: $osu-red;
}

body {
  margin: 0;
  font-family: proximanova,  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --root-background-color: #ffffff;
  --root-text-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root.dark-mode-enabled {
  --root-background-color: #121212;
  --root-text-color: #ffffff;
}

#root {
  background-color: var(--root-background-color);
  color: var(--root-text-color);
  height: 100vh;
}

@media(max-width: 767px) {
  #page-content {
      min-height: calc(100vh - 42px - 52px - 0.50rem - 260px); // root - navbar - header - main y margins - footer
  }
  footer {
      height: 260px;
  }
}

@media(min-width: 768px) {
  #page-content {
      min-height: calc(100vh - 42px - 52px - 0.50rem - 200px); // root - navbar - header - main y margins - footer
  }
  footer {
      height: 200px;
  }
}

.outline-none {
  outline: none;
}

.required::after {
  content: "*";
  color: $osu-red;
}

/* react-loader */
.loader {
  position: relative;
  height: 3rem;
}

.react-bootstrap-table .table-header {
  background-color: rgba(0,0,0,.03);
}

/* bootstrap 4 table sort icons */
.react-bootstrap-table th .order-4 { // sortable, but unsorted
  display: inline-flex; // wrap when necessary
  font-size: 12px;
}
.react-bootstrap-table th [class*='caret-4-'] { // sorted asc and desc
  display: inline-flex; // wrap when necessary
  font-size: 12px;
}

/* table accessibility for small screens */
@media only screen and (max-width: 767px), (min-device-width: 768px) and (max-device-width: 1024px) {
  .react-bootstrap-table .table table, thead, tbody, th, td, tr { /* force table to not be a table anymore */
      display: block;
  }

  .react-bootstrap-table .table thead tr { /* add border to table header row */
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .react-bootstrap-table .table thead tr th { /* remove additional borders from table header */
      border: 0 !important;
      text-align: left !important;
  }

  .react-bootstrap-table .table tr:first-of-type { /* first row has extra padding already */
      padding-top: 0;
  }

  .react-bootstrap-table .table tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 1rem;
      padding-top: 1rem;
  }

  .react-bootstrap-table .table tr:last-of-type { /* remove last border since there is already a horizontal rule */
      border-bottom: 0;
  }
  
  .react-bootstrap-table .table td { /* behave like a row */
      border-top: 0 !important;
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
      min-height: 2rem;
      padding-left: 9rem !important;
      position: relative;
  }

  .react-bootstrap-table td.react-bs-table-no-data {
    padding-left: 0 !important;
  }

  .react-bootstrap-table .table td:before { /* behave like a table header */
      content: attr(data-header);
      font-weight: bold;
      left: 0rem;
      margin-left: 1rem;
      position: absolute;
      white-space: nowrap;
  }
}

.reward-image {
  height: 5rem;
  object-fit: scale-down;
  width: 100%;
}

.button-bar button {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.osu-red-btn {
  background-color: $osu-red;
  color: white;
  border-color: rgba(187, 0, 0, .5);
}

.osu-red-btn.floating {
  box-shadow: 4px 4px 10px grey;
}
.osu-red-btn:hover {
  background-color:  #860303;
  border-color: #9a0303;
}