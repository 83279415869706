.reward-year-input {
    max-width: 5rem;
}

.reward-level-input {
    max-width: 4rem;
}

.reward-image-form-group {
    max-width: 30rem;
    min-width: 23rem;
}

.reward-image-upload-form-group {
    margin-left: 2rem;
}

.reward-image-file {
    margin-right: 0.2rem;
    max-width: 36rem;
    z-index: 0;
}

.reward-categories-label {
    font-size: inherit;
}

.reward-category-input {
    height: auto !important;
}

.reward-create-btn {
    width: 8rem;
}

.reward-award-btn {
    background-color: #ddd;
    border: none;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
}

.eward-award-btn:hover {
    background-color: #f1f1f1;
  }